import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Language.module.scss";

const Language = ({ lang, onLangSelect }) => {
  const { t, i18n } = useTranslation();

  const languages = i18n.options.fallbackLng;
  return (
    <div className={styles.main}>
      <div className={styles.title}>Language:</div>
      <div className={styles.languages}>
        {languages.map((language) => (
          <div
            className={`${styles.lang} ${
              lang === language ? styles.active : ""
            }`}
            key={language}
            onClick={() => onLangSelect(language)}
          >
            {language}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Language;
