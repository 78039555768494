import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

import Main from "./components/Main/Main";

const App = () => {
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate replace to="/en" />} />
          <Route path="/:lang" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </ParallaxProvider>
  );
};

export default App;
