import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import Answer from "../Answer/Answer";
import styles from "./Question.module.scss";

const Question = forwardRef(
  (
    {
      question,
      userQuestion,
      onAnswerSelect,
      className,
      onCustomAnswerChange,
      onCustomAnswerSelect,
    },
    ref
  ) => {
    let timerId = null;

    const handleAnswerSelect = ({ answerId }) => {
      onAnswerSelect({ answerId, questionId: question.id });
    };

    const handleCustomAnswerSelect = (e, value) => {
      if (timerId) clearTimeout(timerId);

      timerId = setTimeout(() => {
        onCustomAnswerSelect(question.id, value);
      }, 100);
    };

    const handleCustomAnswerChange = (e) => {
      const text = e.target.value;
      onCustomAnswerChange({ questionId: question.id, text });
    };
    const { t } = useTranslation();

    return (
      <div className={`${styles.main} ${className}`}>
        <div className={styles.title}>{question.title}</div>
        <div className={styles.description}>
          {question.isMultiple ? `(${t("questionText")})` : ""}
        </div>
        <div className={styles.answers}>
          {question.answers.map((answer) => (
            <Answer
              answer={answer}
              checked={userQuestion?.answerIds.includes(answer.id)}
              key={answer.id}
              onAnswerSelect={handleAnswerSelect}
            />
          ))}
          {question.hasCustomAnswer ? (
            <div className={styles.customAnswer}>
              <div
                onClick={handleCustomAnswerSelect}
                className={`${styles.customAnswerMarker} ${
                  userQuestion.customAnswer ? styles.checked : ""
                }`}
              />
              <input
                onInput={handleCustomAnswerChange}
                onBlur={(e) => handleCustomAnswerSelect(e, true)}
                className={`${styles.customAnswerInput} ${
                  userQuestion.customAnswer ? styles.checked : ""
                }`}
                placeholder={t("inputPlaceholder")}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

export default Question;
