import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./Form.module.scss";
import { ReactComponent as ButtinIcon } from "../../assets/svg/button.svg";

const Form = ({ value, onSubmit, onEmailInput }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <div className={styles.mainMainButton}>
        <div className={styles.mainButton}>
          <button className={styles.button} onClick={onSubmit}>
            <ButtinIcon className={styles.buttonIcon} />
            <div className={styles.buttonText}>{t("sendButton")}</div>
          </button>
        </div>
        <input
          value={value}
          className={`${styles.input}`}
          placeholder="email"
          onInput={(e) => onEmailInput(e.target.value)}
        />
      </div>
      <div className={styles.infoPrivacyPolicy}>
        <p className={styles.privacyPolicy}>
          <Trans
            i18nKey="privacyPolicy.part1"
            components={{
              highlight: <span></span>,
              a: (
                <a
                  href="files/Privacy Policy Project D.pdf"
                  target="_blank"
                ></a>
              ),
            }}
          />
        </p>
        <p className={styles.privacyPolicy}>
          <Trans
            i18nKey="privacyPolicy.part2"
            components={{ highlight: <span></span> }}
          />
        </p>
      </div>
    </div>
  );
};

export default Form;
