import React from "react";
import { useTranslation, Trans } from "react-i18next";

import styles from "./Welcome.module.scss";

const Welcome = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.main} ${className}`}>
      <div className={styles.welcome}>
        <p>
          <Trans
            i18nKey="hello.part1"
            components={{ highlight: <span></span> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="hello.part2"
            components={{ highlight: <span></span> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="hello.part3"
            components={{ highlight: <span></span> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="hello.part4"
            components={{ highlight: <span></span> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="hello.part5"
            components={{ highlight: <span></span> }}
          />
        </p>
      </div>
      <div className={styles.important}>
        <Trans
          i18nKey="hello.part6"
          components={{ highlight: <span></span> }}
        />
      </div>
    </div>
  );
};

export default Welcome;
