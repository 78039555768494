import React from "react";
import styles from "./Answer.module.scss";

const Answer = ({ answer, checked, onAnswerSelect }) => {
  return (
    <div
      className={styles.main}
      onClick={() => onAnswerSelect({ answerId: answer.id })}
    >
      <div className={`${styles.marker} ${checked ? styles.checked : ""}`} />
      <div className={`${styles.title} ${checked ? styles.checked : ""}`}>
        {answer.title}
      </div>
    </div>
  );
};

export default Answer;
