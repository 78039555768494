/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import styles from "./Main.module.scss";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as LogoOutline } from "../../assets/svg/logo-outline.svg";
import Welcome from "../../components/Welcome";
import Question from "../../components/Qestion/Question";

import Language from "../../components/Language/Language";
import Form from "../../components/Form/Form";
import { api } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Parallax } from "react-scroll-parallax";

const Main = () => {
  const { i18n, t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [userQuestions, setUserQuestions] = useState([]);
  const [email, setEmail] = useState("");
  const [lang, setLang] = useState(null);

  const mainRef = useRef();
  const questionsRef = useRef();
  const logoOutlineRef = useRef();

  useEffect(() => {
    handleLangSelect(params.lang || "en");
  }, []);

  useEffect(() => {
    if (!lang) return;

    const fetchData = async () => {
      try {
        const { data } = await api.get(`questions?lang=${lang}`);

        setQuestions(data);
        setUserQuestions(
          data.map((question) => ({
            id: question.id,
            answerIds: [],
            customAnswerText: "",
            customAnswer: false,
          }))
        );
      } catch (error) {}
    };

    fetchData();
  }, [lang]);

  const handleLangSelect = (language) => {
    setLang(language);
    navigate({ pathname: `/${language}` });
    i18n.changeLanguage(language);
  };

  const handleCustomAnswerChange = ({ questionId, text }) => {
    setUserQuestions(
      userQuestions.map((question) =>
        question.id === questionId
          ? { ...question, customAnswerText: text }
          : question
      )
    );
  };

  const handleCustomAnswerSelect = (questionId, value) => {
    const findQuestion = questions.find(
      (question) => question?.id === questionId
    );

    setUserQuestions(
      userQuestions.map((question) =>
        question.id === questionId
          ? {
              ...question,
              customAnswer:
                value !== undefined ? value : !question.customAnswer,
              answerIds: findQuestion.isMultiple
                ? question.answerIds.length > 2
                  ? [
                      ...question.answerIds.slice(
                        0,
                        question.answerIds.length - 1
                      ),
                    ]
                  : [...question.answerIds]
                : [],
            }
          : question
      )
    );
  };

  const handleAnswerSelect = ({ answerId, questionId }) => {
    const findQuestion = questions.find(
      (question) => question?.id === questionId
    );

    const newUserQuestions = userQuestions.map((question) =>
      question.id === questionId
        ? {
            ...question,
            customAnswer:
              (findQuestion.isMultiple && question.answerIds.length > 1) ||
              !findQuestion.isMultiple
                ? false
                : question.customAnswer,
            answerIds: question.answerIds.includes(answerId)
              ? question.answerIds.filter((answId) => answId !== answerId)
              : findQuestion.isMultiple
              ? question.answerIds.length > 2
                ? [
                    ...question.answerIds.slice(
                      0,
                      question.answerIds.length - 1
                    ),
                    answerId,
                  ]
                : [...question.answerIds, answerId]
              : [answerId],
          }
        : question
    );

    setUserQuestions(newUserQuestions);
  };

  const handleSubmit = async () => {
    const invalideQuestions = getInvalideQuestions();

    if (invalideQuestions.length) {
      const index = questions.findIndex(
        (question) => question.id === invalideQuestions[0].id
      );
      scrollIntoQuestion(index);

      return;
    }
    fetchUserQuestions();
  };

  const msgMap = {
    success: t("success"),
    emailUsed: t("emailUsed"),
    invalideEmail: t("invalideEmail"),
    fillForm: t("fillForm"),
  };

  const scrollIntoQuestion = (index) => {
    toastShow("warning", msgMap.fillForm);

    if (index !== -1) {
      questionsRef.current.children[index].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  const fetchUserQuestions = async () => {
    try {
      await api.post(`user-answers`, {
        email,
        language: lang,
        questions: userQuestions,
      });
      submitPixels(email);
      setEmail("");
      toastShow("success", msgMap.success);
    } catch (error) {
      const { message } = error.response.data;

      if (message) {
        if (Array.isArray(message)) {
          return message.forEach((msg) => {
            if (msg === "email must be an email") {
              toastShow("error", msgMap.invalideEmail);
            }
          });
        }

        if (message === "Email already exists") {
          return toastShow("error", msgMap.emailUsed);
        }

        return toastShow("error", "Something get wrong");
      }

      return toastShow("error", "Something get wrong");
    }
  };

  const getInvalideQuestions = () => {
    return userQuestions.filter((question) => {
      if (
        question.answerIds.length ||
        (question.customAnswer && question.customAnswerText.length)
      ) {
        return false;
      }

      return true;
    });
  };

  const toastShow = (type, text) => {
    toast[type](text, {
      position: "top-right",
      theme: "dark",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: styles.toast,
    });
  };

  const submitPixels = (email) => {
    if (window.VK) {
      window.VK.Goal("purchase", { value: 1 });
    }

    if (window.fbq) {
      window.fbq("track", "Purchase", {
        content_name: email,
        currency: "USD",
        value: 1,
      });
    }

    if (window.snaptr) {
      window.snaptr("track", "PURCHASE", {
        currency: "USD",
        price: 1,
        item_category: email,
      });
    }

    if (window.twq) {
      window.twq("event", "tw-ocj9j-ocjpg", {
        value: 1,
        currency: "USD",
        email_address: email,
      });
    }
  };

  return (
    <div ref={mainRef} className={styles.main}>
      <div className={styles.background} />

      <Parallax speed={30} className={styles.logoOutline}>
        <LogoOutline ref={logoOutlineRef} />
      </Parallax>

      <div className={styles.side}>
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} />
        </div>
      </div>

      <div className={`inner ${styles.inner}`}>
        <ToastContainer />
        <Welcome className={styles.welcome} />
        <div ref={questionsRef}>
          {questions.map((question) => (
            <Question
              className={styles.question}
              question={question}
              userQuestion={userQuestions.find(
                (userQuestion) => userQuestion.id === question.id
              )}
              key={question.id}
              onAnswerSelect={handleAnswerSelect}
              onCustomAnswerChange={handleCustomAnswerChange}
              onCustomAnswerSelect={handleCustomAnswerSelect}
            />
          ))}
        </div>
        <div>
          <div className={styles.info}>
            <p className={styles.infoText}>
              <Trans
                i18nKey="info.part1"
                components={{ highlight: <span></span> }}
              />
            </p>
            <p className={styles.infoText}>
              <Trans
                i18nKey="info.part2"
                components={{ highlight: <span></span> }}
              />
            </p>
          </div>
          <Form
            value={email}
            onSubmit={handleSubmit}
            onEmailInput={setEmail}
            className={styles.main}
          ></Form>
        </div>
      </div>

      <div className={styles.side}></div>

      <div className={styles.language}>
        <Language lang={lang} onLangSelect={handleLangSelect} />
      </div>
    </div>
  );
};

export default Main;
